import { AMSV3Service } from './AMSV3Service';
import { AccessManagementService } from './accessManagementService';
import { CategoriesService } from './categoriesService';
import { CompanyCategoriesService } from './companyCategories';
import { CookiesService } from './cookiesService';
import { DeliveriesService } from './deliveriesService';
import { DeliveryRequestTaskService } from './deliveryRequestTaskService';
import { DeliveryRequestsService } from './deliveryRequestsService';
import { LegalEntitiesService } from './legalEntitiesService';
import { Observable } from '../helpers/observable';
import { OrdersService } from './ordersService';
import { PartnersService } from './partnersService';
import { PricingPoliciesService } from './pricingPoliciesService';
import { PromotionsService } from './promotionsService';
import { ReportsService } from './reportsService';
import { RoutingService } from './routingService';
import { SnackbarService } from './snackbarService';
import { StockTakingReportService } from './stockTakingReportsService';
import { StocktakingsService } from './stocktakingsService';

export const accessManagementService = new AccessManagementService();
export const categoriesService = new CategoriesService();
export const companyCategoriesService = new CompanyCategoriesService();
export const cookiesService = new CookiesService();
export const deliveriesService = new DeliveriesService();
export const deliveryRequestsService = new DeliveryRequestsService();
export const deliveryRequestTaskService = new DeliveryRequestTaskService();
export const legalEntitiesService = new LegalEntitiesService();
export const ordersService = new OrdersService();
export const partnersService = new PartnersService();
export const pricingPoliciesService = new PricingPoliciesService();
export const promotionsService = new PromotionsService();
export const reportsService = new ReportsService();
export const routingService = new RoutingService();
export const snackbarService = new SnackbarService();
export const stockTakingReportsService = new StockTakingReportService();
export const stocktakingsService = new StocktakingsService();

export const initialDataLoaded = new Observable<boolean>(false);

export const amsV3Service = new AMSV3Service();
