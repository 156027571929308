import { CircularProgress, Container, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  accessManagementService,
  amsV3Service,
  categoriesService,
  deliveriesService
} from '../../services/services';
import { usePermissions, useSuppliers } from '../../helpers/hooks';

import { AccessPermissions } from '../../services/accessManagementService';
import { ItemCategoryResponse } from '../../services/api';
import ItemContainer from './tabs/ItemContainer';
import ItemPartnerConfigurationComponent from './tabs/ItemPartnerConfigurationComponent';
import ItemQuantityPromotionsComponent from './tabs/ItemQuantityPromotionsComponent/ItemQuantityPromotionsComponent';
import ItemStockTakingComponent from './tabs/ItemStockTakingComponent';
import LinkedItemsContainer from './tabs/LinkedItemsContainer';
import SimilarItemsContainer from './tabs/SimilarItemsContainer';
import StockContainer from './tabs/StockContainer';
import TabPanel from '../../helpers/ui/TabPanel/TabPanel';
import { parseQuery } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const ItemComponent = ({ location }: { location: any }) => {
  const { id, tabId } = parseQuery(location.search);

  const history = useHistory();

  const [item, setItem] = useState<any>({ isActive: true, isOwnedByUs: true, maintainStock: true });
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<ItemCategoryResponse[]>([]);
  const [activeOrderItems, setActiveOrderItems] = useState<any[]>([]);
  const [stockHistory, setStockHistory] = useState<any[]>([]);
  const [avaragePrice, setAvaragePrice] = useState<number>(0);
  const { suppliers } = useSuppliers();

  const [value, setValue] = React.useState(0);

  const [canReadPromotions] = usePermissions([AccessPermissions.CAN_READ_PROMOTIONS]);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      if (id) {
        const [itemResp, activeOrderItemsResp, categoriesResp, stockHistoryResp] =
          await Promise.all([
            amsV3Service.getItem(id),
            amsV3Service.getItemActiveOrderItems(id),
            categoriesService.getCategoriesFlat(true),
            deliveriesService.getDeliveryItems(id, undefined, undefined, undefined, 25)
          ]);
        setItem(itemResp.data ?? { isActive: true, isOwnedByUs: true, maintainStock: true });
        setActiveOrderItems(activeOrderItemsResp?.data?.data ?? []);
        setCategories(categoriesResp ? categoriesResp.data : []);
        setStockHistory(
          stockHistoryResp
            ? stockHistoryResp.data.map((s: any) => ({
                ...s,
                deliveryDate: new Date(s.deliveryDate)
              }))
            : []
        );
        setAvaragePrice(
          stockHistoryResp
            ? stockHistoryResp.data.reduce((res: any, item: any) => {
                res += item.price;
                return res;
              }, 0) / stockHistoryResp.data.length
            : 0
        );
        setLoading(false);
      } else {
        const categoriesResp = await categoriesService.getCategoriesFlat(true);
        if (categoriesResp && categoriesResp.data) {
          setCategories(
            categoriesResp.data.sort(
              (c1: ItemCategoryResponse, c2: ItemCategoryResponse) => c2.id - c1.id
            )
          );
        }
        setItem({ isActive: true, isOwnedByUs: true, maintainStock: true });
        setActiveOrderItems([]);
        setLoading(false);
      }
    };
    loadData();
  }, [id]);

  useEffect(() => {
    if (tabId) {
      if (id) {
        setValue(+tabId);
      } else {
        setValue(0);
        history.push(`item?${id ? `id=${id}` : ''}&tabId=0`);
      }
    } else {
      setValue(0);
    }
  }, [tabId, id, history]);

  const onChange = (field: string, value: any) => {
    setItem({ ...item, [field]: value });
  };

  function a11yProps(index: any) {
    return {
      id: `item-tab-${index}`,
      'aria-controls': `item-tabpanel-${index}`
    };
  }

  const canReadStockTakingReports = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_READ_STOCKTAKING_REPORTS),
    []
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    history.replace(`item?${id ? `id=${id}` : ''}&tabId=${newValue}`);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <div className="text-align-left">
      <Container maxWidth="xl">
        <Typography component="h5" variant="h5">
          {item.id && item.artNo ? `${item.artNo} - ` : ''}
          {item.id && item.name ? item.name : ''}
        </Typography>
        <Grid container>
          <Grid item lg={12}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Информация" {...a11yProps(0)} />
              <Tab disabled={!id} label="Наличности" {...a11yProps(1)} />
              <Tab disabled={!id} label="Мапинги" {...a11yProps(2)} />
              <Tab disabled={!id || !canReadPromotions} label="Промоции" {...a11yProps(3)} />
              {canReadStockTakingReports && (
                <Tab disabled={!id} label="Ревизии" {...a11yProps(4)} />
              )}
              <Tab
                disabled={!id}
                label="Свързани продукти"
                {...a11yProps(canReadStockTakingReports ? 5 : 4)}
              />
              <Tab
                disabled={!id}
                label="Аналози"
                {...a11yProps(canReadStockTakingReports ? 6 : 5)}
              />
            </Tabs>
          </Grid>
          <Grid item lg={12}>
            <TabPanel value={value} index={0}>
              <ItemContainer
                item={item}
                onChange={onChange}
                categories={categories}
                suppliers={suppliers}
                reload={() => {
                  setItem({ isActive: true, isOwnedByUs: true, maintainStock: true });
                }}
                stockData={{
                  labels: stockHistory
                    .sort((a, b) => a.deliveryDate - b.deliveryDate)
                    .map((s) => s.deliveryDate.toISOString().substr(0, 10)),
                  datasets: [
                    {
                      label: 'Цена',
                      data: stockHistory.map((s) => s.price),
                      fill: true,
                      backgroundColor: 'rgba(75,192,192,0.2)',
                      borderColor: 'rgba(75,192,192,1)'
                    },
                    {
                      label: 'Средна цена',
                      data: stockHistory.map(() => avaragePrice.toFixed(2)),
                      fill: true,
                      backgroundColor: 'rgba(62,86,176,0.2)',
                      borderColor: 'rgba(62,86,176,1)'
                    }
                  ]
                }}
                activeOrderItems={activeOrderItems}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <StockContainer id={id} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ItemPartnerConfigurationComponent itemId={id} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ItemQuantityPromotionsComponent itemId={+id} />
            </TabPanel>
            {canReadStockTakingReports && (
              <TabPanel value={value} index={4}>
                <ItemStockTakingComponent itemId={id} />
              </TabPanel>
            )}
            <TabPanel value={value} index={canReadStockTakingReports ? 5 : 4}>
              <LinkedItemsContainer id={id} />
            </TabPanel>
            <TabPanel value={value} index={canReadStockTakingReports ? 6 : 5}>
              <SimilarItemsContainer id={id} />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ItemComponent;
